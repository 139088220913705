

export const config = {
    NG_PUBLIC_KEY: "pk_test_52aeef908cd21ae4788357e80516430640960912",
    PK: "pk_test_51Iz7WPHGWoYrTWNKvKj3F81E9KZRGdXwHFPPoMpUeaqKV2zGD3cMVWONQHqBantCxEWIIOSidzvXUlTWQWYfwLEQ00i1VbjK7U",
    //GH_PUBLIC_KEY: "pk_test_326385fbf9c6cc526f70b18220fb669fa57f088c",
    GH_PUBLIC_KEY: "pk_test_51eeb211e601057b8d3ebd25ea2e841766af326f",
    webKey: "YPurgYreKws_Fi1e1DXfy-dJxpQ0J3T8uVikIRQw49VkAzFuhKf2PA=="
}

export const baseUrl = "https://oonpay.com"
export const ImagebaseUrl = "https://oonpaystorage.blob.core.windows.net"
