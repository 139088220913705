<template>
  <div>
    <the-app-bar />
    <food-hub-sub-categories />
    <Footer />
  </div>
</template>

<script>
import FoodHubSubCategories from "../../../components/FoodHub/foodHubSubCategories.vue";
import Footer from "../../../components/footer.vue";
import TheAppBar from "../../../components/NavBar/TheAppBar.vue";
export default {
  components: { TheAppBar, Footer, FoodHubSubCategories }
};
</script>

<style lang="scss" scoped></style>
