<template>
  <div>
    <div class="row py-24 px-12 oonpay-bg">
      <div class="col-md-12">
        <h4 class="font-semibold text-center text-oonpaytext text-3xl">
          Food Hub Subcategories
        </h4>
      </div>
    </div>

    <div v-if="loading" class="row py-5 justify-content-center">
      <fade-loader :loading="loading" :color="color"></fade-loader>
    </div>

    <div v-else>
      <div class="row justify-content-center pt-5">
        <div class="col-md-5">
          <div class="form-group">
            <label for="">Selected Super Category</label>
            <select
              @change="handleSubcategory"
              name=""
              class="form-control"
              v-model="id"
            >
              <option
                v-for="item in supercategories"
                :key="item.id"
                :selected="id === item.id ? 'selected' : null"
                :value="item.id"
                >{{ item.fullName }}</option
              >
            </select>
          </div>
        </div>
      </div>

      <div class="row py-5">
        <div class="container">
          <div class="col-md-12 text-center">
            <p class="font-montserrat  font-semibold text-black p-3 text-2xl">
              Select sub category
            </p>
          </div>
          <div class="row text-center">
           
            <div class="col-md-3" v-for="item in subcategories" :key="item.id">
              <router-link :to="`/add-foodhub-cart/${item.id}`" class="card">
                <img
                 v-if="item.imageUrl"
                  v-lazy="
                    `${foodhubbaseUrl}/oonpayfoodhubsetup/${item.imageUrl}?cache=${uniqueParam}`
                  "
                  class="card-img-top fixed-size-image"
                  alt="Product Image"
                  @load="onLoad"
                />
                <div v-if="isLoading" class="spinner">
                  <img src="@/assets/Spinner.gif" alt="Loading..." />
                </div>
                <div class="card-body text-center">
                  <h5 class="card-title">{{ item.fullName.length > 25 ? item.fullName.slice(0, 25) + '...' : item.fullName }}</h5>


                  <router-link
                    :to="`/add-foodhub-cart/${item.id}`"
                    class="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 active:bg-blue-700 disabled:opacity-50 mt-4 w-full flex items-center justify-center"
                  >
                    Buy Pack
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 ml-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>
                  </router-link>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import { ImagebaseUrl } from "../../config";

export default {
  data() {
    return {
      id: this.$route.params.id,
      foodhubbaseUrl:ImagebaseUrl,
      showpacks: false,
      package_for: "",
      uniqueParam: Date.now(),
      isLoading: true,
      loading: false,
      color: "#0b4369"
    };
  },

  components: {
    FadeLoader
  },

  id(newValue) {
    localStorage.setItem("paramsId", newValue);
  },
  computed: {
    ...mapGetters("foodhub", ["supercategories", "subcategories"])
  },

  created() {
    // Listen for the beforeunload event to show the spinner
    window.addEventListener("beforeunload", this.showSpinnerOnReload);
  },
  destroyed() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener("beforeunload", this.showSpinnerOnReload);
  },

  mounted() {
    this.getSubcategories();
    this.$store.dispatch("foodhub/getFoodHubSuperCategories");
    localStorage.setItem("paramsId", this.id);
  },
  methods: {
    showSpinnerOnReload() {
      // Set showSpinner to true when the page is reloading
      this.loading = true;
    },

    onLoad() {
      this.isLoading = false;
    },

    getSubcategories() {
      this.loading = true;
      this.$store
        .dispatch("foodhub/getFoodHubSuberCategories", this.id)
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
          }
        });
    },

    handleSubcategory(e) {
      console.log(e.target.value)
      localStorage.setItem("categoryId", e.target.value);
      localStorage.setItem("paramsId", e.target.value);
      this.$store.dispatch("foodhub/getFoodHubSuberCategories", e.target.value);
      this.$router.replace({ params: { id: e.target.value } });
    }
  }
};
</script>

<style scoped>
.fixed-size-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.oonpay-bg {
  background: #0c4369;
}

.bg-color {
  background: #f7f4fd;
}

.oonpay-bg {
  background: #0c4369;
}
</style>
